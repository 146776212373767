.section-1 {
  margin-bottom: 150px;
}

.wrap-figure {
  background-image: url("../../Assets/photos/photo-offers.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto;
  height: 700px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-bottom: 150px;
}

.wrap-txt-figure {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 4rem;
  font-weight: 500;
  color: var(--secondary-color);
}

.wrap-txt-section-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
}

.wrap-txt-section-1 p:nth-of-type(2) {
  line-height: 1.5rem;
}

.section-5 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    margin-bottom: 150px;
  }
  
  .wrap-all-txt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
 .wrap-content-section-4 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 110px;
}

.section-5 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.wrap-all-txt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.txt-1-section-5 {
  text-align: center;
  background-color: var(--gray);
  padding: 2rem 15rem;
  margin-left: -60px;
}

.txt-3-section-5 {
  text-align: justify;
}

.txt-4-section-5 {
  text-align: end;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
}

.txt-4-section-5::after {
  content: "";
  display: block;
  width: 44%;
  border-bottom: 1px solid var(--black);
  transition: 0.3s;
  position: absolute;
  bottom: -3px;
  right: 0;
}


.txt-4-section-5:hover::after {
  width: 0%;
}

.wrap-txt-section-5 {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 2.5rem 2.5rem 2.5rem 2rem;
  background-color: var(--white);
  margin-left: -60px;
}

.list-section-5 li {
  list-style-type: none;
}

.list-section-5 p {
  margin-bottom: 5px;
}

.img-section-5 {
  width: 500px;
  height: auto;
}

.wrap-contents-section-6{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.wrap-txt-section-6{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
}

.wrap-txt-2-section-6{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
}
