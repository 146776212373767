.sidebar {
    position: fixed;
    left: -360px;
    top: 0;
    width: 355px;
    height: 100%;
    background: #ffffff;
    color: white;
    transition: left 0.3s;
    border-right: solid 1px #e6e6e6;
    z-index: 2000;
  }
  
  .wrap-section-1-sidebar{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  .wrap-options-sidebar{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  .wrap-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    transition: all 0.2s;
  }

  .wrap-menu:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
  #close-btn{
    padding:25px 0px 0px 50px;
  }

  #close-btn:hover{
    cursor: pointer;
  }

  .logo-sidebar{
    height: 70px;
  }

  .wrap-logo-and-close{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
  }
  
  .sidebar.show {
    left: 0;
  }
  
  .sidebar-options {
    list-style-type: none;
    border-top: solid 1px var(--border-color);
    border-bottom: 1px solid var(--border-color);
    padding: 15px 0px 15px 0px;
  }

  .sidebar-options li, .sidebar-options-2  li, .sidebar-options-3{
    text-transform: uppercase;
    font-weight: 500;
    padding: 15px;
    margin-left: 10px;
    transition: all 0.1s;
  }

  .sidebar-options li a, .sidebar-options-2 li a {
    color: var(--black);
    text-decoration: none;
  }
  
  .sidebar-options li:hover, .sidebar-options-2 li:hover {
    transform: scale(1.02);
    cursor: pointer;
  }

  .sidebar-options-3 img:hover  {
    transform: scale(1.07);
    cursor: pointer;
  }

  .sidebar-options-2{
    border-bottom: 1px solid var(--border-color);
  }

  .wrap-options-redes-sidebar{
    display: flex;
    flex-direction: row;
    gap: 20px;
  }