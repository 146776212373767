@import url("https://fonts.googleapis.com/css2?family=Gupter:wght@400;500;700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1.300;1.400;1.500;1.600;1.700;1.800;1.900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gupter:wght@400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Belleza&display=swap');


:root {
    --primary-color: rgb(7, 7, 7); 
    --secondary-color: aliceblue;
    --hover-color: #808080; 
    --gray:  rgb(248, 248, 248);
    --white: rgb(255, 255, 255);
    --black: rgb(0, 0, 0); 
    --dark-gray: #575757; 
    --light-gray: #f1f1f1; 
    --border-color: #e6e6e6; 
    --shadow-color: rgba(0, 0, 0, 0.2); 
    --font-color-light: rgb(117, 117, 117); 
    --border-dolor: #d8d8d8;
  }


* {
    margin: 0;
    padding: 0;
    font-family: "Montserrat", sans-serif;
}

.container{
    position: relative;
    width: 75%;
    max-width: 1200px; 
    margin: 0 auto; 
  }

.txt-forma::after {
    content: ' ✦'; 
    font-size: 0.7rem;
    margin-left: 15px;
    color: rgb(124, 124, 124);
}

.txt-bola::after {
    content: '  •'; 
    margin-left: 10px;
}

.txt-forma-before::before{
    content: ' ✦'; 
    font-size: 0.7rem;
    margin-right: 8px;
    color: rgb(85, 85, 85);
}

.txt-bola-before::before{
    content: ' •'; 
    color: rgb(85, 85, 85);
    margin-left: 5px;
    margin-right: 5px;
}

.txt-end{
    text-align: end;
}

.icons-sm{
    height: 15px;
    width: auto;
}

.icons-m{
    height: 18px;
    width: auto;
}

.icons-g{
    height: 30px;
    width: auto;
}

.font-xxs{
    font-size: 0.640rem;
    font-weight: 300;
    line-height: 1.1rem;
    color: rgb(117 117 117);
}

.font-xs{
    font-size: 13px;
    font-weight:300;
    line-height: 1.5rem;
    color: rgb(0, 0, 0);
}

.font-sm{
    font-size: 0.8rem;
}
.font-s{
    font-size: 0.9rem;
    text-transform: uppercase;
}

.font-s-lowercase{
    font-size: 0.9rem;
}

.font-s-bold{
    font-family: "Montserrat", serif;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    letter-spacing: 1px;
}

.font-mm {
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.4rem;
    align-items: center;
}

.font-m{
    font-size: 1.6rem;
    text-transform: uppercase;
}

.font-g{
font-size: 2.5rem;
  text-transform: uppercase;
  font-weight: 400;
}

.btn-border{
    display: inline-block;
    border: solid 1px rgb(151, 151, 151);
    padding: 10px 30px 10px 30px;
    text-transform: uppercase;
    font-size: 0.9rem;
    transition: all 0.3s;
}

.btn-border:hover{
    border: solid 1px black;
    cursor: pointer;
}

.link-no-style{
        color: inherit;
        text-decoration: none;
}

.line-button-left {
    text-align: end;
    text-transform: uppercase;
    position: relative;
    display: inline-block;;
  }
  
.line-button-left::after {
    content: "";
    width: 100%;
    border-bottom: 1px solid var(--black);
    transition: 0.3s;
    position: absolute;
    bottom: -3px;
    left: 0;
  }
  
.line-button-left:hover::after {
    width: 0%;
  }

.line-button-right {
    text-align: end;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
  }
  
.line-button-right::after {
    content: "";
    display: block;
    width: 100%;
    border-bottom: 1px solid var(--black);
    transition: 0.3s;
    position: absolute;
    bottom: -3px;
    right: 0;
  }

.line-button-right:hover::after {
    width: 0%;
  }