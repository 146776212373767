
* {
    margin: 0;
    padding: 0;
    font-family: "Montserrat", sans-serif;
  }
  
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    margin: 0px 80px 0px 80px;
    letter-spacing: 2px;
  }
  
  .logo {
    width: 150px;
  }
  
  .wrap-logo {
    margin-left: 340px;
  }
  
  .wrap-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }
  
  .wrap-options {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
  }
  
  .wrap-reserva {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    background-color: var(--gray);
    padding: 10px 15px 10px 15px;
  }
  
  .option {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }

  .dropdown {
    position: relative;
  }
  
  .dropdown-toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  
  .dropdown-menu p {
    margin: 0;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .dropdown-menu p:hover {
    background-color: #f1f1f1;
  }
  
  .fixed-navbar {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: rgb(7, 7, 7);
    z-index: 1000;
    transition: all 1s;
  }
  
  .wrap-navbar {
    display: inline-block;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-top: solid #d8d8d8 1px;
    border-bottom: solid #d8d8d8 1px;
    background-color: var(--gray);
    padding: 15px 0px 13px 0px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .navbar-options {
    display: flex;
    flex-direction: row;
    gap: 20px;
    list-style-type: none;
  }

  .wrap-options{
    z-index: 1;
  }

  .wrap-navbar ul.navbar-options li a:hover {
    text-shadow: 0 0 1px currentColor;
  }
  
  .navbar-options li a,
  .option,
  .wrap-reserva,
  .wrap-menu {
    transition: all 0.2s;
  }
  
  .navbar-options li a:hover,
  .option:hover,
  .wrap-reserva:hover,
  .wrap-menu:hover {
    transform: scale(1.1);
    cursor: pointer;
  }