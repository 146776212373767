.footer {
  margin-top: 10rem;
  padding-top: 2rem;
  border-top: solid #d8d8d8 1px;
  padding-bottom: 2rem;
  margin-bottom: 4rem;
}

.contents{
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 50px;
}

.container-footer{
    position: relative;
    width: 70%;
    max-width: 1200px; 
    margin: 0 auto; 
  }

.container-contents-footer{
    width: 100%;
    max-width: 400px; 
    margin: 0 auto; 
  }
  
.wrap-group-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
}

.number-1 a,
.number-2 a {
  text-decoration: underline;
}

.number-1 p,
.number-2 p,
.address p {
  color: black;
  letter-spacing: 1px;
}

.number-1,
.number-2,
.address {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.imgs-social-media{
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.social-media{
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.imgs-social-media img{
  transition: all 0.4s;
}

.imgs-social-media img:hover{
  transform: scale(1.1);
  cursor: pointer;
}

.txt-2-content-1-footer {
  text-align: end;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
}

.txt-2-content-1-footer::after {
  content: "";
  display: block;
  width: 100%;
  border-bottom: 1px solid black;
  transition: 0.3s;
  position: absolute;
  bottom: -3px;
  left: 0;
}

.txt-2-content-1-footer:hover::after {
  width: 0%;
}

.txt-1-content-2-footer, .txt-1-content-3-footer, .wrap-group-1 p {
    font-weight: 500;
}

.content-2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
}

.contents{
  border-bottom: solid #d8d8d8 1px;
  padding-bottom: 4rem;
  margin-bottom: 3rem;
}

.form-group {
  position: relative;
  margin-bottom: 15px;
  margin-right: -70px;
  margin-top: 10px;
}

input {
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 12px 5px 12px 0;
  font-size: 16px;
  width: 60%;
  box-sizing: border-box;
  background: none;
}

label {
  position: absolute;
  top: 12px;
  left: 0;
  color: #aaa;
  font-size: 16px;
  transition: 0.2s ease all;
  pointer-events: none;
}

input:focus + label,
input:not(:placeholder-shown) + label {
  top: -10px;
  left: 0;
}

input:focus {
  outline: none;
}

.wrap-form-group{
  display: flex;
  flex-direction: row;
}

#nowrap{
  white-space: nowrap;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  max-width: 100%; 
  display: block;
}

.form-group {
  position: relative;
  margin-bottom: 15px; 
}

.wrap-form-group {
  display: flex;
  flex-direction: column; 
}

.btn-1-form {
  margin-top: 2rem; 
}


input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* input[type="number"] {
    -moz-appearance: textfield;
}
  */
 
.btn-1-footer{
  margin-top: 2rem;
}

.btn-2-footer{
  margin-top: 2rem;
  white-space: nowrap;
}

.btn-1-form{
  margin-top: 2rem;
}

.txt-copyright{
  max-width: 600px;
  text-align: justify;
}

.content-4 div p span{
  font-weight: 500;
  color: black;
}

