.section-1{
    margin-top: 150px;
    margin-bottom: 150px;
}

.wrap-txt-section-1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
    gap: 15px;
}