.section-1-the-hotel {
  margin-bottom: 200px;
}

.wrap-figure-the-hotel {
  margin-bottom: 90px;
}

.wrap-txt-figure--the-hotel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrap-txt-figure--the-hotel {
  background-image: url(../../Assets/photos/foto-frente-hotel.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto;
  height: 700px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.wrap-txt-figure-the-hotel::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.267);
  pointer-events: none;
}

.wrap-txt-figure-the-hotel * {
  position: relative;
  z-index: 2;
}

.wrap-figure-the-hotel p {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 4rem;
  font-weight: 500;
  color: var(--secondary-color);
}

.background-section-1-the-hotel {
  background-image: url(../../Assets/photos/scenario.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  height: 470px;
  width: 70%;
  margin: 0 auto;
}

.txt-section-1-the-hotel {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 28.125rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
  padding: 20px;
  line-height: 1.9rem;
}

.txt-section-1-the-hotel span {
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.4rem;
  align-items: center;
}

.background-section-2-the-hotel {
  background-image: url(../../Assets/photos/scenario-2.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  display: block;
  height: 440px;
  margin: 0 auto;
}

.txt-section-2-the-hotel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  gap: 15px;
  line-height: 1.9rem;
  width: 480px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255);
  padding: 70px 70px 70px 70px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.txt-section-2-the-hotel::before {
  content: "";
  position: absolute;
  top: 21px;
  left: 21px;
  right: 21px;
  bottom: 21px;
  border: 1px solid red;
  pointer-events: none;
  margin-left: 12px;
  margin-right: 12px;
}

.txt-section-2-the-hotel::after {
  content: "";
  position: absolute;
  top: 27px;
  left: 27px;
  right: 27px;
  bottom: 27px;
  border: 1px solid red;
  pointer-events: none;
}

.txt-section-2-the-hotel span {
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.4rem;
  align-items: center;
}

.section-2-the-hotel {
  margin-bottom: 200px;
}

.txt-section-3-the-hotel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;
}

.txt-section-3-the-hotel span {
  width: 740px;
  line-height: 1.6rem;
}

.section-3-the-hotel {
  margin-bottom: 200px;
}

.section-4-the-hotel{
  margin-bottom: 200px;
}

.wrap-content-section-4-the-hotel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 110px;

}
.img-section-4-the-hotel {
  width: 540px;
}

.wrap-txt-section-4-the-hotel {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 20px;
  width: 390px;
}

.wrap-txt-section-4-the-hotel p{
  text-align: justify;
}

.section-5-the-hotel{
  margin-bottom: 200px;
}

.wrap-content-section-5-the-hotel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 110px;
}
.img-section-5-the-hotel {
  width: 540px;
}

.wrap-txt-section-5-the-hotel {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
  width: 390px;
}

.wrap-txt-section-5-the-hotel p{
  text-align: justify;
}

.section-6-the-hotel{
  margin-bottom: 200px;
}

.wrap-content-section-6-the-hotel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 110px;
}
.img-section-6-the-hotel {
  width: 540px;
}

.wrap-txt-section-6-the-hotel {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 20px;
  width: 390px;
}

.wrap-txt-section-6-the-hotel p{
  text-align: justify;
}

.section-7-the-hotel{
  margin-bottom: 10px;
}

.background-section-7-the-hotel{
  background-image: url(../../Assets/photos/scenario-3.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  height: 470px;
  width: 70%;
  margin: 0 auto;
}

.wrap-content-section-7-the-hotel{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.wrap-txt-section-7-the-hotel{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
  gap: 20px;
  width: 390px;
}

.section-8-the-hotel{
  margin-bottom: 200px;
}

.wrap-content-section-8-the-hotel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 110px;
}
.img-section-8-the-hotel {
  width: 540px;
}

.wrap-txt-section-8-the-hotel {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 20px;
  width: 390px;
}

.wrap-txt-section-8-the-hotel p{
  text-align: justify;
}

.wrap-txt-section-9-the-hotel {
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: 20px;
  width: 390px;
}

.wrap-txt-section-9-the-hotel p{
  text-align: justify;
}

.wrap-contents-section-9-the-hotel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.wrap-content-1-section-9-the-hotel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  gap: 30px;
  max-width: 200px;
  padding: 82px 60px 82px 60px;
}

.wrap-content-2-section-9-the-hotel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  gap: 30px;
  max-width: 200px;
  padding: 60px 60px 60px 60px;
}

.wrap-content-1-section-9-the-hotel span,
.wrap-content-2-section-9-the-hotel span {
  font-family: "Belleza", sans-serif;
  font-size: 24px;
  font-weight: lighter;
}

.wrap-content-1-section-9-the-hotel::before,
.wrap-content-2-section-9-the-hotel::before {
  content: "";
  position: absolute;
  top: 21px;
  left: 21px;
  right: 21px;
  bottom: 21px;
  border: 1px solid #d8d8d8;
  pointer-events: none;
  margin-left: 12px;
  margin-right: 12px;
}

.wrap-content-1-section-9-the-hotel::after,
.wrap-content-2-section-9-the-hotel::after {
  content: "";
  position: absolute;
  top: 27px;
  left: 27px;
  right: 27px;
  bottom: 27px;
  border: 1px solid #d8d8d8;
  pointer-events: none;
}

