.section-1 {
  margin-bottom: 150px;
}

.wrap-figure {
    background-image: url("../../Assets/photos/photo-activities.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
    height: 700px;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-bottom: 150px;
  }

.wrap-txt-figure {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 4rem;
    font-weight: 500;
    color: var(--secondary-color);
  }

.wrap-txt-section-1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
}

.wrap-txt-section-1 p:nth-of-type(2) {
  line-height: 1.5rem;
}

.wrap-content-section-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 110px;

}
.img-section-2 {
  width: 540px;
}

.wrap-txt-section-2 {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 20px;
  width: 390px;
}

.wrap-txt-section-2 p:nth-of-type(2){
  text-align: justify;
}

.section-2{
  margin-bottom: 200px;
}

.wrap-content-section-3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 110px;
}

.img-section-3 {
  width: 540px;
}

.wrap-txt-section-3 {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
  width: 390px;
}

.wrap-txt-section-3 p:nth-of-type(2){
  text-align: justify;
}

.wrap-txt-section-3 p:nth-of-type(1){
  text-align: start;
}

.section-3{
  margin-bottom: 200px;
}

.wrap-content-section-4 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 110px;

}
.img-section-4 {
  width: 540px;
}

.wrap-txt-section-4 {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 40px;
  width: 390px;
}

.wrap-txt-section-4 p:nth-of-type(2), .wrap-txt-section-4 p:nth-of-type(3){
  text-align: justify;
}

.section-4{
  margin-bottom: 200px;
}

.wrap-contents-section-5{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.wrap-txt-section-5{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
}

.wrap-txt-2-section-5{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
}
