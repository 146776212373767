* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.wrap-figure {
  position: relative;
  background-image: url("../../Assets/photos/quarto.png");
  background-size: cover;
  background-position: center;
  height: 700px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wrap-txt-figure {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrap-figure p {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 4rem;
  font-weight: 500;
  color: var(--secondary-color);
}

.wrap-figure a {
  margin-top: 20px;
  padding: 10px 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 500;
  color: var(--secondary-color);
  border: solid 2px var(--secondary-color);
  transition: all 0.4s;
}

.wrap-figure a:hover {
  color: var(--hover-color);
  border: solid 2px var(--hover-color);
  cursor: pointer;
}

.wrap-section-1 {
  margin: 80px 0 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.img-section-1 {
  height: 300px;
}

.txt-1-section-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#section-1-txt-sm {
  font-family: "Gupter", serif;
  font-weight: 500;
  font-style: normal;
  color: var(--font-color-light);
  font-size: 100px;
  line-height: 100px;
}

#txt-2-section-1 {
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5rem;
  color: var(--font-color-light);
  align-items: center;
  max-width: 900px;
  margin-bottom: 140px;
}

.section-2 {
  margin-bottom: 60px;
}

.wrap-section-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


#section-2-txt-2 {
  font-family: "Montserrat", serif;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  max-width: 700px;
  letter-spacing: 1px;
  margin-bottom: 40px;
}

.wrap-photo-section-2 {
  height: 580px;
  padding-bottom: 20px;
}

.wrap-photo-section-2 {
  display: block;
  margin-top: auto;
}

.border-bottom-wrap-photo-section-2 {
  width: 1300px;
  height: 1px;
  background-color: var(--border-color);
  margin: 20px auto 0;
  margin-bottom: 15px;
}

.wrap-desc {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
}

.wrap-section-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 11px;
}

.img-content-section-3 {
  width: 650px;
  height: 600px;
}

.section-3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 65px;
  margin-bottom: 150px;
}

.wrap-img-section-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.wrap-content-section-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.section-4 {
  margin-bottom: 150px;
}

.wrap-txt-section-4 {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: end;
  max-width: 400px;
  gap: 25px;
}

#txt-1-section-4 {
  text-align: end;
}

#txt-2-section-4 {
  text-align: justify;
}


#txt-4-section-4 {
  z-index: 2;
  font-family: "Gupter", serif;
  opacity: 5%;
  font-size: 10rem;
  margin-top: -400px;
  margin-left: 400px;
}

.img-content-section-3 {
  width: 500px;
  height: 400px;
}

.wrap-content-section-4 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 110px;
}

.section-5 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.wrap-all-txt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.txt-1-section-5 {
  text-align: center;
  background-color: var(--gray);
  padding: 2rem 15rem;
  margin-left: -60px;
}

.txt-3-section-5 {
  text-align: justify;
}

.txt-4-section-5 {
  text-align: end;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
}

.txt-4-section-5::after {
  content: "";
  display: block;
  width: 44%;
  border-bottom: 1px solid var(--black);
  transition: 0.3s;
  position: absolute;
  bottom: -3px;
  right: 0;
}


.txt-4-section-5:hover::after {
  width: 0%;
}

.wrap-txt-section-5 {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 2.5rem 2.5rem 2.5rem 2rem;
  background-color: var(--white);
  margin-left: -60px;
}

.list-section-5 li {
  list-style-type: none;
}

.list-section-5 p {
  margin-bottom: 5px;
}

.img-section-5 {
  width: 500px;
  height: auto;
}
